@import '../../Assets/CSS/variables.scss';

.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5rem;
  color: $color-grey-medium;
  padding: 20px 10px 20px 24px;
  background-color: $color-primary-black;
}
.back-arrow {
  position: absolute;
}
.heading-text {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-left: 10px;
}
.stateHeaders {
  position: fixed;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  color: $color-light-white;
}
