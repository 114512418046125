.swipe-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8rem;
  color: #fafafa;
}
.textblogdetail-heading {
  margin-bottom: 1.2rem;
  font-size: 16px;
  font-weight: 600;
}
.textblogdetail-img {
  margin-bottom: 0.8rem;
}

.textblogdetail-subheading {
  font-size: 16px;
  margin-top: 1rem;
  font-weight: 600;
}
.textblogdetail-subdesc {
  font-size: 13px;
  margin-top: 0.6rem;
  line-height: 1.4rem;
}
