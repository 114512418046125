@import '../../Assets/CSS/variables.scss';

.sort-vehicle-main {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.sort-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: $color-primary-card;
}
.sort-main {
  background-color: $color-primary-card;
  padding-bottom: 2rem;
}
.sort-button {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
  align-items: center !important;
}
.sortclass {
  height: 100%;
  background: #030303cf;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}