@import '../src/Assets/CSS/variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 300px !important;
}
body {
  color: $color-pure-white;
  font-family: 'Poppins', sans-serif;
  background-color: $color-primary-black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body > iframe { display: none }
::-webkit-scrollbar {
  display: none;
}
/* For iFrame */
// iframe {
//   pointer-events: none;
// }
/*  */
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: none;
  cursor: pointer;
}
a {
  all: unset;
}
.skeleton-main {
  background-color: $color-pure-white;
  width: 50%;
  height: 50%;
}

.hide {
  display: none !important;
}

select {
  -webkit-appearance: menulist;
}
.desktop-img {
  width: 100vw;
  height: 98vh;
  max-width: none;
  max-height: 100vh;
  object-fit: scale-down;
}
.hdfcergo-logo {
  height: 2rem;
}
.powered-by-text {
  font-size: 12px;
  letter-spacing: 0.3px;
}
.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding-bottom: 1rem;
}

.tooltip-container {
  cursor: pointer;
  position: relative;
  display: inline;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  .tooltip {
    opacity: 0;
    z-index: 99;
    color: #646464;
    width: 160px;
    display: block;
    font-size: 11px;
    padding: 4px;
    border-radius: 3px;
    text-align: left;
    // text-shadow: 1px 1px 2px #111;
    background: white;
    border: 1px solid #ccc;
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    position: absolute;
    // left: 0px;
    // top: 40px;
    left: -5px;
    top: 23px;
  }

  .tooltip:before,
  .tooltip:after {
    content: '';
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    // border-bottom: 10px solid rgba(51, 51, 51, 0.9);
    background: white;
    transform: rotateZ(40deg);
    position: absolute;
    width: 20px;
    height: 18px;
    top: -10px;
    z-index: -1;
    left: 6%;
  }
}

.tooltip-container:hover .tooltip,
a.tooltip:hover {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.error-page{
  position: relative;
  width: 100vw;
  height: 100vh;
  .div{
    position: absolute;
    // background-color: red;
    top: 50%;
    width: 100vw;
    text-align: center;

  }
  button{
    display: flex;
    padding: 0.8rem 3rem;
    background-color: #e41e26;
    outline: none;
    border: none;
    color: white;
    border-radius: 4px;
    margin: 10px auto;
    cursor: pointer;
  }
}