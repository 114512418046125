.clickable-wrapper {
  padding: 10px;
  background-color: #141414;
  position: relative;
}

.text-headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  color: #fff;
}

.click-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  min-height: 180px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.click-card-one {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #000;
  /* column-gap: 10px; */
  background-color: #f3be0e;
  min-width: 87%;
  /* margin-top: 14px; */
  border-radius: 10px;
  height: 110px;
  overflow: visible;
  position: relative;
}

.click-card-one-para {
  font-weight: 500;
  font-size: 16px;
}

.display-arrow {
  display: inline-block;
  margin-left: 5px;
}

.inner_image_one {
  width: 190px;
  position: absolute;
  top: -40px;
  left: 142px;
  z-index: 100;
}

.click-card-two {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: fit-content;
  color: #fff;
  /* column-gap: 10px; */
  background-color: #421281;
  min-width: 87%;
  border-radius: 10px;
  height: 110px;
  position: relative;
}

.click-card-two-para {
  font-weight: 500;
  font-size: 16px;
}

.inner_image_two {
  width: 188px;
  position: absolute;
  top: 7px;
  left: 138px;
  z-index: 100;
}

.click-card-three {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: fit-content;
  color: #fff;
  /* column-gap: 10px; */
  background-color: #ee5931;
  min-width: 87%;
  border-radius: 10px;
  height: 110px;
  position: relative;
}

.click-card-three-para {
  font-weight: 500;
  font-size: 16px;
}

.inner_image_three {
  width: 155px;
  position: absolute;
  top: -18px;
  left: 167px;
  z-index: 100;
}

.click-card-four {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: fit-content;
  color: #fff;
  /* column-gap: 10px; */
  background-color: #3183ee;
  min-width: 87%;
  border-radius: 10px;
  height: 110px;
  position: relative;
}

.click-card-four-para {
  font-weight: 500;
  font-size: 16px;
}

.inner_image_four {
  width: 217px;
  position: absolute;
  top: 10px;
  left: 126px;
  z-index: 100;
}

.click-card-five {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: fit-content;
  color: #fff;
  /* column-gap: 10px; */
  background-color: #0da157;
  min-width: 87%;
  border-radius: 10px;
  height: 110px;
  position: relative;
}

.click-card-five-para {
  font-weight: 500;
  font-size: 16px;
}

.inner_image_five {
  width: 107px;
  position: absolute;
  top: -24px;
  left: 192px;
  z-index: 100;
}

.click-card-six {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #000;
  column-gap: 10px;
  background-color: #81124a;
  min-width: 130px;
  margin-top: -3px;
  border-radius: 10px;
  color: #fff;
  min-height: 100px;
  position: relative;
}
.view-all-text {
  border-bottom: 1px solid #ffff;
}
.insight-text {
  display: flex;
  align-items: center;
  gap: 12px;
}
.insight-text-sub {
  font-weight: 400;
  font-size: 17px;
}
.insight-img {
  width: 24px;
}
