@import '../../Assets/CSS/variables.scss';

.card_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: $color-pure-white;
  column-gap: 10px;
  background-color: #115173;
  width: 95%;
  height: 78px;
  padding: 10px;
  border-radius: 4px;
  margin: 0 auto;
}
