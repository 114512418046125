.UserPreference {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  color: #e8e8e8;
}
.UserPreference_area {
  margin-top: 80px;
  margin-left: 16px;
  margin-bottom: 36px;
}
.UserPreference_card {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.UserPreference_card_p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fafafa;
}
.UserPreference_card_p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 8px auto;
  color: #c8c8c8;
}

.UserPreference_plannin {
  width: 90%;
  height: 70px;
  background-color: #1b2028;
  border: 1px solid #fbb540;
  border-radius: 6px;
  color: #c8c8c8;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.UserPreference_plannin:first-child {
  border: 1px solid #05acd3;
}
.UserPreference_plannin:last-child {
  border: 1px solid #52cc7b;
}
.UserPreference_plannin > img {
  width: 48px;
  height: 48px;
  margin: 12px;
  /* margin-right: 18px; */
}
.vrline {
  width: 41px;
  height: 0px;
  border: 1px solid #919191;
  transform: rotate(90deg);
}

.UserPreference_plannin > span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
}
