.splash-wrapper {
  padding: 5px;
}
.splash_main_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.splash-content-container {
  width: 100%;
  flex: 1;
}

.splash-main-heading {
  color: #c8c8c8;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-main-para {
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  flex-direction: column;
}

.get-started {
  background-color: inherit;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px 5px;
  margin: 0 auto;
  outline: none;
  border: 1px solid #00af3c;
  width: 300px;
  height: 46px;
  cursor: pointer;
  transform: translateX(-50%);
}

.get-started-arrow {
  padding-left: 16px;
}
.get-started-text {
  font-size: 14px;
  letter-spacing: 1px;
}

.splash_tracker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.splash_tracker .splash_track {
  width: 100%;

  border-radius: 20px;
  max-height: 5px;
}

.completed {
  background-color: #e42125 !important;
}

.track-active {
  max-height: 2px;
  background-color: #e42125 !important;
}

.splash-img-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  position: relative;
  top: 0;
  animation: mymove 5s infinite;
  flex: 2;
  animation-duration: 3s;
}

.splash-img {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

@keyframes mymove {
  50% {
    top: 0px;
  }

  100% {
    top: -1000px;
  }
}

.splashMainBlock {
  position: fixed;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splashContainerBlock {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.splashAnimationBlock {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 365px;
  height: 200px;
  text-align: center;
  z-index: 0;
  display: block;
  transform: translateX(-50%);
}

.splashAnimationBlock > .evlogo {
  position: absolute;
  left: 50%;
  width: 10px;
  animation: zoom-in-zoom-out 2s ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%);
}

.splash-btn-container {
  position: absolute;
  bottom: 20%;
  left: 50%;
  animation-iteration-count: 1;
  animation: rvshow 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  animation-delay: 4s !important;
}

.logotitle {
  position: absolute;
  text-align: left;
  font-size: 0px;
  top: -8px;
  left: 60%;
  opacity: 0;
  animation-delay: 2s !important;
  animation: font-in 2s ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%);
}

@keyframes zoom-in-zoom-out {
  0% {
    width: 10px;
    left: 50%;
  }

  50% {
    width: 100px;
    left: 50%;
  }

  100% {
    width: 100px;
    left: 36%;
  }
}

@keyframes font-in {
  0% {
    font-size: 30px;

    opacity: 0.2;
    /* left: 50%; */
  }

  100% {
    opacity: 1;
    left: 64%;
  }
}

@keyframes rvshow {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0%);
  }
}

@keyframes zoom-in-zoom-outt {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
