$color-pure-black: #000000;
$color-black-medium: #0c0c0c;
$color-primary-black: #141414;

$color-primary-card: #1b2028;

$color-pure-white: #ffff;
$color-light-white: #fafafa;

$color-grey-shade: #555555;
$color-grey-light: #d8d8d8;
$color-grey-medium: #c8c8c8;
$color-grey-dark: #8d8d8d;

$color-primary-red: #e41e26;
$rose-pink: #c21e66;
