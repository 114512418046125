@import '../../Assets/CSS/variables.scss';

.check-state-button-main {
  flex: 0.5;
  align-self: center;
  margin-top: 20px;
}
.check-state-button {
  padding: 0.5rem;
  width: 102%;
  border-radius: 5px;
  border: none;
  background: transparent;
  border: 1px solid $color-light-white;
  color: $color-light-white;
}
.strc-img {
  width: 60% !important;
}
.ev-policy-text {
  margin: 0 auto;
}
.carousel-dots-active {
  background-color: $color-pure-white;
  /* padding: 1rem; */
  width: 1rem;
}

.carousel-prev.block,
.carousel-next.block {
  opacity: 1 !important;
}
/* .carousel-dots {
  padding: 1rem;
} */
.carousel-dots {
  /* margin-top: 5rem !important; */
  position: fixed;
  // bottom: 1.2rem;
  width: 100%;
}
.carousel-dots li.carousel-dots-active button:before {
  color: $color-pure-white !important;
}
.carousel-dots li button:before {
  color: $color-pure-white;
}

/* Override */
.carousel .control-dots {
  bottom: -4% !important;
}
/* For Side Arrow */
.carousel.carousel-slider .control-arrow {
  display: none !important;
}
/* Status */
.carousel .carousel-status {
  display: none !important;
}

.slider-main {
  /* padding: 0rem 1rem 0rem 1rem; */
  /* display: flex; */
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-color: red; */
}

.slider-main div:nth-child(2) {
  /* display: block; */
  /* margin-top: 40px; */
}

/* Background */
.css-wf16b5 {
  right: 0 !important;
  background-color: $color-primary-black !important;
}

.apnaclass {
  height: 100%;
  /* background-color: royalblue; */
}
.tax-benefit-wrapper {
  height: 100%;
  /* background-color: green; */
  position: relative;
  padding: 16px;
  /* height: 100px; */
}

.sticky-header {
  z-index: 99;
}
.batteryDesc {
  /* margin-bottom: 30px; */
  /* margin-bottom: -13rem; */
  flex: 1;
  color: $color-light-white;
  margin-left: 2rem;
}
tr,
td {
  border-color: #96d4d4;
}
.src-source {
  padding: 0 10px;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 0.5px;
}



.carousel-dots li.carousel-dots-active button:before {
  color: #14AE5C !important;
}
.carousel-dots li.carousel-dots-active button:before {
  opacity: 1;
  color: black;
}
.carousel-dots li button:before {
  color: #14AE5C;
}
.carousel-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color:#14AE5C;
  border: 0;
  background: #141414;
}
.carousel-dots li button:before {
  font-size: 40px;
}