.terms-mainn {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  position: fixed;
  background-color: #141414;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.terms-header {
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 1rem 0rem 2rem 0rem;
  background: #141414;
  width: 100%;
}
.btn-close {
  position: absolute;
  left: 0;
  padding-left: 1rem;
}
/*  */
.signup-main {
  padding: 1rem 1rem 1rem 1rem;
}
.create-account-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.create-text {
  color: #c8c8c8;
  font-size: 1.7rem;
}
.pleasefill-text {
  color: #8d8d8d;
}
.input-boxes-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.text-boxes {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  color: #c8c8c8;
}
.text-boxDisable {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  color: #c8c8c8;
  background-color: gray;
}
.text-boxDisable::placeholder {
  color: #000;
}
.show-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border-radius: 5px;
  border: 1px solid red;
}
.input-boxes {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
}
.input-boxes::placeholder {
  color: #646464;
  font-size: 16px;
  font-weight: 300;
}
.btn-continue {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: #14AE5C;
  color: #fff;
}
.already-text {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 0.5rem;
}
.sign-in {
  cursor: pointer;
  color: #e42125;
  font-size: 18px;
}
.terms-section {
  margin-top: 16px;
  display: flex;
  align-items: inherit;
  gap: 1rem;
}
.terms-check {
  background: #1b2028;
}
.terms-text {
  margin-bottom: 1.5rem;
  color: #8d8d8d;
}
.terms-conditions {
  text-decoration: underline;
  color: #fafafa;
}
.tnc-text {
  top: 0;
  color: white;
  /* background: red; */
  padding: 15px 0px 15px 0px;
  position: absolute;
  text-align: center;
  /* top: 50%; */
  left: 50%;
  /* right: 0%; */
  transform: translate(-50%, 0%);
}
.tnc {
  width: 100%;
  height: 86%;
  padding: 1rem 2rem 1rem 2rem;
  background-color: #141414;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: scroll;
}
.errorMessage {
  color: #e42125 !important;
}
.btn-disabled {
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: grey;
  color: #fff;
}
.text-box-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid red;
  border-radius: 5px;
  color: #c8c8c8;
}
.profile-updated {
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1px;
  color: #c8c8c8;
}
.error{
  color: red;
}
.userPreference_profile {
  .UserPreference_planning{
    height: 70px;
    background-color: #1b2028;
    border-radius: 6px;
    color: #c8c8c8;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    min-width: 87% !important;
    
    
  }
  .UserPreference_planning:active{
    border:1px solid  #05acd3;
  }
  .UserPreference_planning  > img {
    width: 48px;
    height: 48px;
    margin-left: 20px;
    margin-right: 12px; 
  }

  .bordercolor1{
    height: 70px;
    background-color: #1b2028;
    border-radius: 6px;
    color: #c8c8c8;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    min-width: 60% !important;
    border:2px solid  #05acd3;
  }
  .bordercolor2{
    height: 70px;
    background-color: #1b2028;
    border-radius: 6px;
    color: #c8c8c8;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    min-width: 60% !important;
  }

  .bordercolor1  > img {
    width: 48px;
    height: 48px;
    margin-left: 20px;
    margin-right: 12px; 
  }
  .bordercolor2  > img {
    width: 48px;
    height: 48px;
    margin-left: 20px;
    margin-right: 12px; 
  }
  // .vrline {
  //   width: 41px;
  //   height: 0px;
  //   border: 1px solid #919191;
  //   transform: rotate(90deg);
  // }
  .click-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-height: 0px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.input-group-addon{
  border-right: 2px solid darkgrey;
  padding-right: 11px;
}

.verify-otp-button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background:#14AE5C;
  color: #fff;
  &.cancel{
    background: #000;
    border: 2px solid darkgray;
    margin-right: 20px;
  }
}

