.vehicle-info-main {
  padding: 0.5rem;
  // background-color: pink !important;
  background-color: #131313 !important;
}
.vehicle-info-poster {
  width: 100%;
  // border-radius: 5px;
  // height: 136px;
}
.vehicle-info-basic {
  padding: 1rem 0rem 0rem;
}
.vehicle-info-content {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem 0rem 0rem;
}
.vehicle-info-prices {
  display: flex;
  justify-content: space-between;
  width: auto;
  padding: 0.6rem;
  background-color: #1C1C24;
  border-radius: 2px;
}
.vehicle-info-features {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
}
.vehicle-info-features-img {
  padding: 0.5rem;
  background-color: #1C1C24;
  width: 44px;
  height: 44px;
  text-align: center;
  border-radius: 2px;
}
.vehicle-info-heading {
  font-size: 13px;
  color: #c8c8c8;
}
.vehicle-info-sub {
  font-size: 11px;
  color: #8d8d8d;
}
.vehicle-info-exprice {
  font-size: 14px;
  color: #c8c8c8;
}
.exprice {
  font-size: 14px;
  color: #14AE5C;
}

/* Material UI Override */
.boder_div{
 border-bottom: 1px solid #404040;
}
.vehicle-accordion{
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    // background-color: #1C1C24;
  }
}
.sub-accordion{
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    // background-color: black !important;
  }
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  background: black !important;
}
.css-15v22id-MuiAccordionDetails-root {
  background: black !important;
  color: #fff !important;
  padding: 0px 5px 16px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  background-color: none !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
}
.vehicle-accordion {
  padding-top: 1rem;
}
.basic-fact-bg {
  color: #fafafa !important;
  background: #000 !important;
}

/*  */
.flex-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  word-break: break-word;
  margin: 10px;
}

/* Accordion Header */
/* .css-67l5gl.Mui-expanded:last-of-type{
  background-color: #1B2028 !important;
} */
.MuiAccordion-region {
  background: #000000 !important;
  color: #fafafa !important;
}
.css-u7qq7e {
  background-color: #000000 !important;
  color: #fafafa !important;
}
.css-67l5gl:last-of-type {
  background: #1b2028 !important;
}
/* For first Accordion Header */
.css-1iji0d4.Mui-expanded {
  /* border-bottom: 1px solid !important; */
  color: #fafafa;
}
.css-1iji0d4 {
  background-color: #000000;
}
.css-17o5nyn {
  color: #fafafa !important;
}
/* For Local */
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  color: #fafafa;
}
.css-o4b71y-MuiAccordionSummary-content {
  color: #fafafa;
}
/* For Side Border */
.css-67l5gl {
  box-shadow: none !important;
  background-color: none !important;
  color: #fafafa !important;
}
.css-67l5gl {
  background-color: #0000 !important;
}
.arrow{
  margin-left: 17px !important;  
}