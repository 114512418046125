.splash-main {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 3rem;
}

.splash-text > p {
  font-size: 20px;
  font-weight: 700;
}

.splash-text {
  display: flex;
  flex-direction: column;
}

.hdfcergo-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: fixed;
  padding: 10px 10px 20px 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
}

/* Typing Demo  */

/* .splash-wrapper {
    height: 100vh;
    display: grid;
    place-items: center;
  }
  
  .splash-text {
    width: 22ch;
    animation: typing 2s steps(10), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 2em;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  } */
.galleryImg {
  height: 50px;
  width: 80%;
  animation: move 3s infinite ease-in-out;
}

@keyframes move {
  0% {
    transform: scale(1) rotate(0deg);
  }

  100% {
    transform: scale(1.5) rotate(0.1deg);
  }
}

/*  */
#typing {
  width: 0ch;
  color: white;
  float: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 5s steps(22) 1s infinite alternate;
}

#typing span {
  color: green;
}

#crow {
  float: left;

  color: white;
  font-family: consolas;
  font-weight: bold;
  font-size: 16px;
  animation: crow 0.5s linear 0s infinite;
}

@keyframes typing {
  from {
    width: 0ch;
  }

  to {
    width: 10ch;
  }
}

@keyframes crow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
