@import '../../Assets/CSS/variables.scss';

.header-wrapper {
  background-color: $color-primary-black;
  padding: 10px 10px 1px 10px;
}
.main-header {
  margin: 5px 0px;
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  grid-gap: 3rem;
  gap: 3rem;
  height: 44px;
  padding: 4px 0px;
  color: $color-grey-dark;
  padding: 0px 10px 10px 0px;
  background-color: $color-primary-black;
  align-items:center
}
.logo{
  width: 50%;
    text-align: center;
}
.header-logo {
  margin-top: 6px;
  width: 5.5rem;
}
.mission-pragati {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.static-cards {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  overflow: scroll hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.card-one {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: fit-content;
  color: $color-pure-white;
  column-gap: 10px;
  background-color: #411e8f;
  min-width: 88%;
  height: 80px;
  font-size: 10px;
  padding: 10px;
  border-radius: 5px;
}

.card-two {
  display: flex;
  justify-content: space-around;
  max-width: fit-content;
  color: $color-pure-white;
  column-gap: 10px;
  background-color: #20615b;
  min-width: 87%;
  height: 80px;
  font-size: 12px;
  padding: 8px;
  border-radius: 3px;
}

.card-three {
  display: flex;
  justify-content: space-around;
  max-width: fit-content;
  color: $color-pure-white;
  column-gap: 10px;
  background-color: #411e8f;
  min-width: 86%;
  height: 80px;
  font-size: 12px;
  padding: 10px;
  border-radius: 3px;
}

.userIcon {
  width: 46px;
  margin-top: 6px;
}
