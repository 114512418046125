.FCV_Details {
  padding: 12px;
  display: grid;
  row-gap: 10px;
  

  &--Price{
    padding: 10px;
    background-color: #1b2028;
    width: 100%;
    border-radius: 5px;
  }
  &--carName {
    font-size: 15px;
    color: #e8e8e8;
  }
  &--cardDetail{
    display: flex;
  }
  &--carPrice {
    font-size: 18px;
    font-weight: 700;
    color: #14AE5C;
    text-align: end;
  }
  &--showRoom {
    color: #c8c8c8;
  }
}
.FCV_Card {
  padding: 1rem 0.6rem;

  &--main {
    background-image: linear-gradient(0deg, #062121, #044343);
    border-radius: 0.5rem;
    padding: 0.8rem;
  }
  &--owner {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding: 0.6rem;

    &-text {
      color: #c8c8c8;
    }
    &-price {
      font-size: 18px;
      color: #fafafa;
      letter-spacing: 0.6px;
    }
  }
  &-list-main {
    display: grid;
    row-gap: 8px;
    padding: 0.6rem;
  }
  &-list {
    display: flex;
    justify-content: space-between;
  }
}


