@import '../../Assets/CSS/variables.scss';

.suggestions {
  list-style: none;
  background-color: #1C1C24;
  width: 60% !important;
  padding: 0.4rem 0.8rem;
  position: absolute;
  font-size: 15px;
  color: #E2E2E2;
  line-height: 22px;
}
.no-suggestions {
  list-style: none;
  background-color: #1C1C24;
  padding: 0.4rem 0.2rem;
}
.filtered {
  background-color: $color-primary-red;
  width: 8px;
  height: 9px;
  border-radius: 5px;
  position: absolute;
  right: 0.3rem;
}
.km-driven-label {
  margin-top: 1rem;
}
.km-driven {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin-top: 0.6rem;
}

// .find-cost {
//   display: flex;
//   padding: 0.8rem 5rem;
//   background-color: $color-primary-red;
//   outline: none;
//   border: none;
//   color: $color-pure-white;
//   border-radius: 4px;
//   margin: 10px auto;
//   margin-top: 18px;
//   cursor: pointer;
// }
.vehicleError {
  margin-top: 15px;
  color: $color-primary-red;
  font-size: medium;
}
