@import '../../Assets/CSS/variables.scss';

.rvLoaderBlock {
  position: relative;
  height: 5px;
  width: 100%;
}
.rvAni3 {
  top: 0;
  width: 0;
  height: 5px;
  background: $color-primary-red;
  position: absolute;
  animation: mymovervAni3 6s;
  /* animation-play-state: paused; */
}
.rvAni2 {
  top: 0;
  width: 100%;
  height: 5px;
  /* background: green; */
  position: absolute;

  /* animation: mymovervAni3 5s; */
  /* animation-play-state: paused; */
}

@keyframes mymovervAni3 {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
