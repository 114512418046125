.video-blogs-main {
  margin: 10px;
}

.iframe {
  width: 100% !important;
  height: 200px !important;
}

.html5-video-player {
  height: 65% !important;
}
iframe:fullscreen {
  border: none !important;
  padding: 0 !important;
}
.video-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.yt-thumbnail{
  max-width: 100%;
}


.yt-iframe{
  background: #f5f3f3;
  height: max-content;
  background-size: cover;
  background-position: center;

}