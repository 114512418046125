.chek-vehicle-container{
    // margin: 0.6rem;
.card-container {
    display: flex;
    flex-flow: column wrap;
    padding: 14px 14px 10px 14px;
    background-color: #1b2028;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 5px;
    .varient{
        margin-top: 10px;
    }
}
.title-div{
    color: #A5A5A5;
    font-size: small;
}
.vehile_Title{
    font-size: 14px;
    color:  #FFFFFF;
    font-family: 'Lato';
    font-weight: 700;
}
.subTitle{
    font-size: 13px;
    color: #E2E2E2;
    padding-top: 3px;
}
.sub-subTitle{
    font-size: 12px;
    color: #E2E2E2;
    padding-top: 3px;
}
.img{
    // width: 70%;
}
.check-no{
    color: #14AE5C;
    font-size: 16px;
    padding: 10px 0px;
}
.reg-title{
    font-size: 16px;
}
.reg-details{
    margin: 5px 0px;
    font-size: 13px;
}
.align_-div{
    text-align: end;
}
.left-title{
    color: #B0B0B0;
    font-size: 14px;
}
.lineBreack{
    word-break: break-word !important;
    color: #E2E2E2;
    font-size: 14px;
}
.border-div{
    border-bottom: 1px solid white;
    margin: 3px 0px;
}

}
.errormessage{
    text-align: center;
    font-size: x-large;
    margin-top: 50%;
}
