.container {
  background-color: #141414;
}

.tab-list {
  height: 45px;
  display: flex;
  list-style: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
  margin: 0px 5px;
  background: #1b2028;
  padding: 6px;
}

.tabs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #D3D3D3;
}

// .tabs:not(:last-child) {
//   transition: 1s;
// }

.active-tabs {
  background-color: #14AE5C;
  border-radius: 3px;
}

.active-tabs::before {
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);

  background: #000;
}

.content {
  display: none;
  background-color: #eeeded;
  padding: 7px;
}

.active-content {
  display: flex;
  flex-direction: column;
  background-color: #131313;
  color: #fff;
  gap: 0.8rem;
}
.css-n2n0ek {
  width: 2px !important;
}
