.signup-main {
  padding: 2rem 1rem 1rem 1rem;
}
.create-account-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.create-text {
  font-size: 1.7rem;
}
.pleasefill-text {
  color: #888;
}
.input-boxes-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.text-boxes {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
}
.show-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid red;
  border-radius: 5px;
}

.input-boxes {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
}
.input-boxes::placeholder {
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
}
.btn-continue {
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: #14AE5C;
  color: #fff;
}
.terms-text {
  margin-bottom: 1.5rem;
  color: #8d8d8d;
}
.already-text {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 0.5rem;
}
.sign-in {
  cursor: pointer;
  color: #14AE5C;
  font-size: 18px;
}
.terms-section {
  display: flex;
  align-items: inherit;
  gap: 1rem;
}
.terms-conditions {
  text-decoration: underline;
  color: #fafafa;
}

.terms-check {
  background: #1b2028;
}
.btn-disabled {
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: grey;
  color: #fff;
}
.input-boxes::placeholder {
  color: #858282;
}

