@import '../../Assets/CSS/variables.scss';

.no-station-wrapper {
  display: flex;
  flex-direction: column;
}

.no-station-found {
  color: $color-grey-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px auto;
  row-gap: 20px;
}

.install-para {
  color: $color-light-white;
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}
