.content-container{
    .title-head{
        padding-bottom: 10px;
        font-size: 12px;
        color: #A5A5A5;
    }
    .title{
        font-size: 12px;
        color: #A5A5A5;
    }
    .sub-Data{
        font-size: 14px;
        padding-top: 3px;
    }
    
}
.no-panding{
    text-align: center;
    margin: 6rem;
}
.paidIcon{
    margin-left: 10px;
    margin-top: 2px;
}