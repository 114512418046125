@import '../../Assets/CSS/variables.scss';

.cost-of-vehicle-main {
  background-color: $color-primary-black;
  color: $color-pure-white;
  padding: 0.6rem;
}
.cost-of-vehicle-sub {
  margin-top: 1rem;
  background: $color-primary-black;
  width: 100%;
}
.cost-of-vehicle-input_container {
  display: flex;
  border: solid 1px $color-pure-white;
  border-radius: 3px;
  transition: border-color 0.1s ease-in;
  width: 100%;
  margin-top: 0.5rem;
}

.cost-of-vehicle-input {
  display: block;
  width: calc(100% - 20px);
  border: none;
  outline: none;
  padding: 8px 16px;
  color: $color-pure-white;
  background-color: $color-primary-black;
}

.cost-of-vehicle-input_img {
  flex-basis: 20px;
  display: inline-block;
  padding: 10px 12px 4px 16px;
  cursor: pointer;
}
.tab-content-container {
  background-color: $color-primary-black;
}
.vehicle-name {
  margin-left: 1rem;
}
