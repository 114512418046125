.CE-Main {
  margin: 16px 10px 10px 10px;
  display: grid;
  row-gap: 1rem;
}
.CE-TOC {
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  &--Card {
    display: grid;
    grid-template-columns: auto auto auto;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 14px;
  }
  &--Item {
    padding: 4px 0px 6px 0px;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    text-align: center;

    &--text {
      display: inline-block;
      font-size: 11px;
      color: #a5a5a5;
    }
    &--value {
      display: inline-block;
      font-size: 16px;
      color: #fafafa;
    }
  }
  &--Itemm {
    padding: 4px 0px 6px 0px;
    border: 1px solid #14AE5C;

    border-radius: 5px;
    text-align: center;

    &--text {
      font-size: 11px;
      color: #c8c8c8;
    }
    &--value {
      font-size: 16px;
      color: #fafafa;
    }
  }
  &--vehicle_charged {
  }
  &--amount-card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 90px;
    border-radius: 5px;
    background: #1c1c24;
  }
  &--assumptions {
    color: #a5a5a5;
    &-text {
    }
  }
}
.CE-TOC--assumptions-text {
  font-size: 16px;
  font-weight: 500;
  color: #e2e2e2;
}

.toggle-button-sub {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 4px;
  padding: 3px;
}

.btn {
  width: 50%;
  border-radius: 4px;
  border: none;
  color: #a7a7a7;
  background-color: transparent;
  outline: none;
  padding: 12px;
}
.btn.active {
  width: 50%;
  background-color: #14AE5C;
  color: #ffffff;
}
.CE-TOC--assumptions-list {
  list-style: number outside none;
  display: list-item;
  margin-left: 26px;
  line-height: 22px;
  font-size: 13px;
}
.CE-TOC--text {
  font-size: 16px;
  color: #e2e2e2;
  margin-top: 2px;
  margin-bottom: 10px;
}
.CE-TOC--vehicle_charged {
  font-size: 15px;
  color: #e2e2e2;
  margin-top: 12px;
  margin-bottom: 10px;
}
.CE-TOC--amount {
  font-size: 24px;
}

// For Range
.CE-Slider {
  height: 3px;
  -webkit-appearance: none;
  cursor: pointer;
  width: 100%;
}

.CE-Slider {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
}

.CE-Slider:focus {
  outline: none;
}

.CE-Slider::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  border-radius: 1em;
  background: #14AE5C;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(3px * 0.5 - 10px * 0.5);
  -webkit-appearance: none;
}

.CE-Slider::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 0.5em;
  background: #3a3a3a;
  border: none;
  box-shadow: none;
}

.vehicle-charge-label {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  justify-content: space-between;
  margin-top: 8px;
}

.slider-block {
  position: relative;
  height: 50px;
  .CE-Slider {
    margin-top: 40px;
  }
  .slide-icon {
    position: absolute;
  }
}

.radioCalc {
  display: none;
}

input[type='radio']:checked + .abc {
  font-weight: bold;
  background-color: green;
}

// .abc input[type='radio']:checked + label {
//   font-weight: bold;
//   background-color: green;
//   color: green;
// }

.btnn {
  width: 50%;
  border-radius: 4px;
  border: none;
  color: #a7a7a7;
  background-color: transparent;
  outline: none;
  padding: 12px;
}
.btnn.active {
  width: 50%;
  background-color: #14AE5C;
  color: #ffffff;
}
