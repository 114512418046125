.droom-footer-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
}
