.otp-main {
  padding: 2rem 1rem 1rem 1rem;
}
.confirm-otp-main {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.confirm-text {
  color: #c8c8c8;
  font-size: 20px;
}
.otp-text {
  color: #8d8d8d;
  margin-top: 1rem;
}

.receive-text {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 0.5rem;
  color: #8d8d8d;
}
.resend-text {
  font-size: 18px;
  cursor: pointer;
  color: #14AE5C;
}
.otp-box-user {
  display: flex;
  justify-content: center;
}
.otp-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}
.otp-button {
  background-color: #e42125;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 0.7rem 4rem 0.7rem 4rem;
  border-radius: 8px;
}
/*  */
.terms-mainn {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  position: fixed;
  background-color: #141414;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.terms-header {
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 1rem 0rem 2rem 0rem;
  background: #141414;
  width: 100%;
}
.btn-close {
  position: absolute;
  left: 0;
  padding-left: 1rem;
}
