@import '../../Assets/CSS/variables.scss';

.react-loading-skeleton {
  width: 90% !important;
  height: 7rem !important;
  margin: 10px auto !important;
  padding: 10px !important;
  background-color: $color-primary-card !important;
  border-radius: 4px !important;
  margin-top: 1rem !important;
  display: flex !important;
  box-shadow: 0px 10px 5px -7px $color-pure-black !important;
}

.loader {
  border: 16px solid $color-light-white;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
