@import '../../Assets/CSS/variables.scss';

.find-vehicle-main {
  background-color: $color-primary-black;
  padding: 0.6rem 0rem;
}
.find-vehicle-input_container {
  display: flex;
  border-radius: 3px;
  transition: border-color 0.1s ease-in;
  width: 60%;
}

.find-vehicle-input_container_condition {
  // display: flex;
  border-radius: 3px;
  transition: border-color 0.1s ease-in;
  width: 100%;
  padding: 5px;
}

.find-vehicle-input {
  display: block;
  width: calc(100% - 0px);
  border: none;
  outline: none;
  padding: 15px 16px;
  color: $color-pure-white;
  background-color: #252525;
  border-radius: 5px 0px 0px 5px;
}

.find-vehicle-input_img {
  flex-basis: 20px;
  display: inline-block;
  padding: 10px 12px 4px 16px;
  cursor: pointer;
  background-color: #252525;
  border-radius: 0px 5px 5px 0px;
}
.nav-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-pure-white;
}
.sort-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 0.8rem;
}

.sort-fiter-img {
  width: 50%;
}
.vehicle-list-main {
  padding: 8px;
  margin-top: 1rem;
  background-color: $color-primary-card;
  border-radius: 4px;
}
.vehicle-list-img {
  width: 100%;
}
.car-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $color-pure-white;
  padding-top: 5px;
}
.car-price {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.view-button {
  padding: 8px 15px;
  background-color: #14AE5C;
  border: none;
  border-radius: 3px;
  color: $color-pure-white;
  font-size: 12px;
}
.car-name {
  color: #E2E2E2;
  font-size: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}
.car-price {
  font-weight: 550;
  font-size: 16px;
}
.onwards {
  font-size: 12px;
  font-weight: 300;
  color: #A5A5A5;
}
