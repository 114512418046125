.tech-spec-para {
  width: 95%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
}

.technical-specifications-wrapper {
  width: 95%;
  margin: 18px auto;
  // background: #131313;
  border: 1px solid #8d8d8d;
  background-color: #1C1C24 !important;
  .border-line{
    margin: 0px 8px;
    border: 1px solid #646464;
  }
}

.kazam-product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #8d8d8d;
}

.kazam-product-value {
  text-align: right;
}

.kazam-interested-para {
  color: #c8c8c8;
  width: 95%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
