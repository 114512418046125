.FCV_radio-main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  overflow-x: scroll;
  overflow-x: scroll;
  width: 100%;
  overflow-y: hidden;
}
.FCV_main {
  background: #363636;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0rem 4rem;
  // width: 208px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;

  &:not(:first-child) {
    margin-left: 1rem;
  }
}

.radio_block {
  display: none;
}

.radioVehicleBox .FCV_label {
  padding: 10px;
  margin: 6px 10px 0 0;
  background: #2e2e2e;
  border-radius: 5px;
  display: inline-block;
  color: #fafafa;
  font-size: 13px;
  cursor: pointer;
  min-width: 150px;
}
.radioVehicleBox .FCV_labell {
  background-color: #14AE5C;
}

.FCV_monthly-km {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.1rem 0rem 1rem 0rem;
  grid-row-gap: 0.4rem;
  row-gap: 0.6rem;
  grid-column-gap: 0.3rem;
  column-gap: 0.8rem;
}
.km-upto {
  border: 1px solid #c8c8c8;
  text-align: center;
  padding: 0.6rem;
  border-radius: 6px;
  font-size: 14px;
}
.radioKmBox .FCV_Km-label {
  padding: 10px;
  background: #141414;
  border: 1px solid #8d8d8d;
  border-radius: 5px;
  display: inline-block;
  color: #fafafa;
  font-size: 13px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.radioKmBox .FCV_Km-labell {
  background-color: #14AE5C;
}

.FCV--searched-terms {
  font-size: 10px;
  color: #8d8d8d;
}
.FCV_Kms-Driven {
  margin: 1rem 1rem 1rem 0rem;
  color: #c8c8c8;
}
.find-cost {
  padding: 0.8rem 5rem;
  background-color: #14AE5C;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.find-cost-disable {
  padding: 0.8rem 5rem;
  background-color: #888;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
