.FCV-Landing-main {
  margin-left: 10px;
  margin-right: 10px;
}
.FCV-Landing-card {
  background-color: #062121;
  color: #e2e2e2;
  padding: 10px;
  font-size: 13px;
}
.FCV-Landing-header {
  text-align: center;
  color: #c8c8c8;
  padding: 16px;
}
.FCV-Landing-services-main {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 52px;
  padding: 10px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  margin-top: 20px;
}
.FCV-Landing-services {
  display: flex;
  align-items: center;
  gap: 10px;
}
.FCV-Landing-content {
  font-size: 13px;
  color: #e2e2e2;
}
.FCV-Landing-btnPlus {
  text-align: center;
  font-size: 26px;
  color: #a7a7a7;
  margin-top: 6px;
  margin-bottom: 6px;
}
.FCV-Landing-loss-invalue {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 10px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
}
.loss-invalue-text {
  font-size: 14px;
  color: #e2e2e2;
}
.loss-invalue-light {
  font-size: 14px;
  color: #a5a5a5;
}
.FCV-Landing-btnMain {
  text-align: center;
  margin-top: 24px;
}
.FCV-Landing-btn {
  width: 100%;
  border: none;
  outline: none;
  background-color: #14AE5C;
  color: #fafafa;
  padding: 16px;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 0.7px;
}
