.UserVehicleInformation {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  max-height: 100%;
  overflow: hidden;
  color: #e8e8e8;
}
.closeButtonSection {
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 1rem 0rem 2rem 0rem;
  background: #141414;
  width: 100%;
}
.preference-main {
  height: calc(100% - 132px);
}

.closeBtn {
  position: absolute;
  left: 0;
  padding-left: 1rem;
}
.UserPreference_area1 {
  margin-top: 50px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 40px;
}
.UserPreference_card1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.UserPreference_card1_p {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  color: #fafafa;
}
.UserPreference_card1_p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 14px auto;
  color: #c8c8c8;
}
.UserPreference_plannin1 {
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
  text-align: center;
}

.UserPreference_plannin1_input {
  background: #1b2028;
  border: 1px solid #646464;
  border-radius: 4px;
  width: 92%;
  height: 100%;
  border-radius: 4px;
  padding-left: 10px;
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: large;
  color: #b0b0b0;
}
.UserPreference_plannin1_input:focus {
  outline: none;
}
.UserPreference_plannin1_button {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.vehicleError {
  margin-left: 16px;
  color: #fc2428;
}
.UserPreference_plannin1_button > button {
  border: none;
  outline: none;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  background-color: #14ae5c;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  padding: 0px 24px;
  gap: 8px;
  letter-spacing: 1px;
}
.UserPreference_plannin1_skip_button {
  background-color: #1b2028 !important;
}
.btn_disabled {
  border: none;
  outline: none;
  border-radius: 4px;
  width: 163.5px;
  height: 44px;
  background-color: grey !important;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  padding: 0px 24px;
  gap: 8px;
}
.skip-now {
  color: #c8c8c8;
  font-size: 16px;
  position: relative;
  /* top: 90%; */
  /* left: 36%; */
  /* transform: translate(-50%, -50%); */
  text-decoration: underline;
  text-underline-offset: 4px;
  text-align: center;
  padding-top: 16px;
  margin-top: 100px;
  height: 40px;
  /* top: 602px; */
  /* top: calc(90vh - 40px);*/
}
.uservehicle-info {
  padding: 1rem 1rem 0rem 1.2rem;
}
