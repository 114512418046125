@import '../../Assets/CSS/variables.scss';

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem 0.4rem 1rem;
  font-size: 20px;
}
.modal-close {
  color: #14AE5C;
}
.checkbox-block {
  display: grid;
  grid-template-columns: auto auto;
}
.filter-checkbox {
  background-color: $color-pure-black;
}
input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}
input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid $color-grey-shade;
  border-radius: 3px;

  background-color: inherit;
}
input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #14AE5C;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.modelName {
  position: fixed;
  background-color: $color-pure-black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.checkBoxLabel {
  margin-left: 0.8rem;
  font-weight: 100;
  font-size: 0.8rem;
}
.checkbox-flex {
  padding: 4px;
}
.brand-text {
  margin-bottom: 0.7rem;
}
.buy-brand {
  margin-bottom: 1rem;
}
.filter_detail{
  padding: 0px 10px;
}
/* Buttons */
.selection-btn-wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.selection-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: 0;
  right: 0;
  bottom: 8px;
}

.vehicle-reset-btn {
  background-color: $color-primary-card;
  border-radius: 4px;
  padding: 13px 65px 13px 65px;
  outline: none;
  border: none;
  font-size: 16px;
  color: $color-light-white;
  font-weight: 300;
  border: 1px solid #14AE5C;
}

.vehicle-apply-btn {
  background-color: #14AE5C;
  border-radius: 4px;
  padding: 13px 65px 13px 65px;
  outline: none;
  border: none;
  font-size: 16px;
  color: $color-pure-white;
  font-weight: 300;
}

/* Slider Override */
input[type='range'] {
  --minRangePercent: 0%;
  --maxRangePercent: 0%;
  height: 0rem;
}
input[type='range']:invalid {
  box-shadow: none;
}

input[type='range']::-webkit-slider-thumb {
}

.min-max-slider {
  position: relative;
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

.min-max-slider::focus {
  oultine: none;
}

input::focus {
  outline: none;
}

.min-max-slider > label {
  display: none;
}

.min-max-slider > input {
  cursor: pointer;
  position: absolute;
}

/* webkit specific styling */
.min-max-slider > input {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  outline: none !important;
  background: transparent;
}
.min-max-slider > input.min {
  background-image: linear-gradient(
    to right,
    silver 0%,
    silver var(--minRangePercent),
    #b4b4b4 var(--minRangePercent),
    #b4b4b4 100%
  );
}

.min-max-slider > input.max {
  background-image: linear-gradient(
    to right,
    #b4b4b4 0%,
    #b4b4b4 var(--maxRangePercent),
    silver var(--maxRangePercent),
    silver 100%
  );
}

.min-max-slider > input::-webkit-slider-runnable-track,
.min-max-slider > input::-moz-range-track,
.min-max-slider > input::-ms-track {
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: orange;
  border-radius: 8px;
  height: 10px;
  background-color: transparent;
  background-image: linear-gradient(orange, orange),
    linear-gradient(orange, orange);
  background-size: var(--sx) 10px, calc(100% - var(--sx)) 4px;
  background-position: left center, right center;
  background-repeat: no-repeat;
}

.min-max-slider > input::focus {
  outline: none;
}

.min-max-slider > input.max::-moz-range-progress {
  background: orange;
  border-radius: 4px;
}

.min-max-slider > input.min::-moz-range-progress {
  height: 0.6em;
  background: silver;
  border-radius: 4px;
}

input[type='range']::-webkit-slider-thumb,
input[type='range']::-moz-range-thumb {
  -webkit-appearance: none; /* Override default look */
  -moz-appearance: none;
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: orange; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border: none;
  color: 1px solid orange;
  border-radius: 50%;
  /* box-shadow: -205px 0 0 200px hsl(100, 100%, 40%); */
}

.min-max-slider > input::-webkit-slider-runnable-track {
  cursor: pointer;
}

input::-webkit-slider-runnable-track,
input::-moz-range-track,
input::-ms-track {
  background-color: $color-primary-red;
  margin: 200px;
}

.multi-range-slider {
  border: none !important;
  box-shadow: none !important;
}

.multi-range-slider .bar-inner {
  background-color: #f0f0f0 !important;
}
.multi-range-slider .thumb::before {
  background-color: #14AE5C;
  box-shadow: none;
}
.App{
  margin: 20px 0px;
}
.App .multi-range-slider .bar-left {
  padding: 2px auto !important;
}
.sliderLabel {
  display: flex;
  justify-content: space-between;
  font-size: 80%;
  padding: 0 5px 0 10px;
}


.multi-range-slider .thumb {
  background-color: green;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.multi-range-slider-black {
  display: flex ;
  position: relative !important;
  background-color: black !important;
  border: none !important;
  flex-direction: column !important;
  color: white !important;
  user-select: none !important;
  margin: 22px 5px 0px 5px !important;
}
.multi-range-slider-black .thumb * {
  position: absolute;
  bottom: 25px !important;
  width: 40px !important;
  height: 30px !important;
  left: -15px !important;
  font-size: 75% !important;
  text-align: center !important;
  line-height: 30px !important;
  background-color: #1A4835 !important;
  border-radius: 10% !important;
  color: white !important;
  box-shadow: 0px 0px 5px black !important;
}


.multi-range-slider-black .thumb::before {
  content: '';
  background-color: white !important;
  position: absolute !important;
  width: 20px !important;
  height: 20px !important;
  border: solid 1px black !important;
  box-shadow: 0px 0px 3px black, inset 0px 0px 5px grey !important;
  border-radius: 50% !important;
  z-index: 1 !important;
  margin: -8px !important;
  cursor: pointer;
}

.multi-range-slider-black .bar-left {
  width: 25%;
  background-color: #14AE5C !important;
  border-radius: 10px 0px 0px 10px !important;
  box-shadow: inset 0px 0px 5px black !important;
  padding: 1px 0px !important;
}
.multi-range-slider-black .bar-right {
  background-color: #14AE5C !important;
}

.hrBorder{
  margin: 0px 10px;
}
