@import '../../Assets/CSS/variables.scss';

.terms-mainn {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  position: fixed;
  background-color: $color-primary-black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.terms-header {
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 1rem 0rem 2rem 0rem;
  background: $color-primary-black;
  width: 100%;
}
.btn-close {
  position: absolute;
  left: 0;
  padding-left: 1rem;
}
.terms-text {
  font-size: 21px;
  font-weight: 500;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.6rem;
  color: #fafafa;
}
.terms-description {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.6rem;
  word-break: break-word;
  word-spacing: 2px;
  color: #c8c8c8;
}
.terms-para {
  color: #b0b0b0;
}
.back-arrow {
}
