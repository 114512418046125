.card-containers {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  grid-gap: 20px;
  gap: 20px;
  border: none;
  padding: 10px 10px 4px 10px;
}

.chargeitup-header {
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  padding: 10px 9px 0px 9px;
  grid-gap: 12px;
  gap: 12px;
}

.chargeitup-header-text {
  font-weight: 400;
}

.nearby-stations-header-text,
.planning-trip-header-text {
  font-weight: 500;
  margin: 10px 0;
  font-size: 12px;
}

// .planning-trip-img {
//   height: 70px;
// }
.nearby-stations-card,
.planning-trip-card {
  display: flex;
  flex-direction: column;
  // row-gap: 1rem;
  font-weight: 400;
  border-radius: 6px;
  padding: 20px 10px 18px 10px;
  flex: 1;
  max-height: fit-content;
  font-size: 10px;
}

.nearby-stations-header-text,
.planning-trip-header-text {
  margin-bottom: 10px;
  font-size: 14px;
}
.nearby-stations-content {
  font-size: 12px;
}
.planning-trip-content {
  font-size: 12px;
}
/*  */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: rgba(255, 255, 255, 0.5)
    radial-gradient(circle, rgba(255, 255, 255, 0.15) 1%, transparent 1%)
    center/15000%;
}
.ripple:active {
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 100%;
  transition: background 0s;
}


.card-containers{ 
   width: 100%;
  .main-container-loan{
     cursor: pointer;
     width: 100%;
     background: linear-gradient(180deg, #3F3351 0%, #1D1826 100%);
     display: flex;
     border-radius: 0.5rem;
     justify-content: space-between;
     flex-direction: column;
     .left{
      display: flex;
      justify-content: space-between;
      width: 100%;
        .item{
          padding: 1rem 0rem 0rem 1rem;
        }
     }
     .right-svg {
      width: 100%;
      display: flex;
      justify-content: space-between;
       .upper-ring {
        margin-left: 61%;
       }
       .lower-image {
         display: flex;
         width: 40%;
         justify-content: flex-end;
       }
     }
  }
}
