.signup-main {
  padding: 5rem 1rem 1rem 1rem;
}
.create-account-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.create-text {
  color: #c8c8c8;
  font-size: 1.7rem;
}
.pleasefill-text {
  color: #8d8d8d;
}
.input-boxes-main {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 2rem;
}
.text-boxes {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  color: #c8c8c8;
}
.show-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border-radius: 5px;
  border: 1px solid red;
}
.input-boxes {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
}
.input-boxes::placeholder {
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
}
.btn-continue {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: #14AE5C;
  color: #fff;
}
.already-text {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 0.5rem;
}
.sign-in {
  cursor: pointer;
  color: #e42125;
  font-size: 18px;
}
.terms-section {
  margin-top: 16px;
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.terms-check {
  background: #1b2028;
}
.terms-text {
  margin-bottom: 1.5rem;
  color: #8d8d8d;
}
.terms-conditions {
  text-decoration: underline;
  color: #fafafa;
}
.tnc-text {
  top: 0;
  color: white;
  /* background: red; */
  padding: 15px 0px 15px 0px;
  position: absolute;
  text-align: center;
  /* top: 50%; */
  left: 50%;
  /* right: 0%; */
  transform: translate(-50%, 0%);
}
.tnc {
  width: 100%;
  height: 86%;
  padding: 1rem 2rem 1rem 2rem;
  background-color: #141414;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: scroll;
}
.errorMessage {
  color: #e42125 !important;
}
.btn-disabled {
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  background: grey;
  color: #fff;
}
.text-box-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: #1b2028;
  border: 1px solid red;
  border-radius: 5px;
  color: #c8c8c8;
}