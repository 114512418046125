.car-name-section {
  display: flex;
  gap: 0.4rem;
  padding-bottom: 6px;
}
.css-1wnsr1i {
  color: #fff !important;
  background-color: #1b2028 !important;
  width: 94% !important;
  border-radius: 10px !important;
  padding: 1.2rem !important;
  border: none !important;
}
.css-2ulfj5-MuiTypography-root {
  font-size: 1rem !important;
}
.pop-button {
  padding: 0.6rem 1.4rem;
  background-color: #1b2028;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  margin: 5px 0px;
}
.pop-button-main {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 0.4rem;
}
.pop-active {
  border: 1px solid #14AE5C;
  color: #14AE5C;
}
.select-header {
  display: flex;
  justify-content: space-between;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.18) !important;
}
.select-block{
  display: grid;
  grid-template-columns: auto auto;
}