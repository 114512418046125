@import '../../Assets/CSS/variables.scss';

.location-displayer {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  color: $color-grey-medium;
  padding: 7px;
  border-bottom: 1px solid $color-grey-medium;
  width: 95%;
  margin: 10px auto;
  background-color: $color-primary-black;
  min-height: 1rem;
}
.current_location_input {
  width: 83vw;
  height: auto;
  border: none;
  outline: none;
  background: transparent;
  color: $color-pure-white;
  word-wrap: break-word;
}
.errorMsg{
  text-align: center;
  font-size: x-large;
  margin-top: 50%;
  padding: 10px;
}
.alterMessage{
  color: red;
  padding: 10px;
}
.alterhide{
  display: none;
}