.clickable-wrapper-block{
  padding: 0 10px;
    background-color: #141414;
    position: relative;
    margin-top: -20px;

}
.lsclick-card-one {
  /* display: flex; */
  align-items: center;
  padding-left: 1rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #000;
  /* column-gap: 10px; */
  // background-color: #f3be0e;
  color: #fafafa;
  min-width: 87%;
  /* margin-top: 14px; */
  border-radius: 10px;
  height: 126px;
  overflow: visible;
  position: relative;
}
.ls-click-main {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 42%;
}
.ls-desc {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.lsclick-card-one-para {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.8px;
}
