.vehicle-wrapper{
    width: 95%;
    margin: 0px auto 10px auto;
    .title{
        margin: 0.6rem;
        font-size: 16px;
        color: #E2E2E2;
    }
    .registration_input{
        color: #E2E2E2 !important;
        background-color: "#242424" !important;
        padding: 0 0.3rem 0 0;
    }
    .css-b52kj1.Mui-disabled {
        -webkit-text-fill-color: white !important;
    }

    .error_message{
        color: red;
        padding: 5px 0px;
        font-size: 14px !important;
    }
    .other-service{
        margin: 0.8rem;
        .other-service-title{
            padding: 0.6rem;
            font-size: 16px;
            color: #E2E2E2;
        }
        .e-challan-satus {
            display: flex;
            align-items: center;
            padding: 4px 10px;
            background-color: #892A2A;
            width: 100%;
            height: -moz-fit-content;
            height: fit-content;
            border-radius: 5px;
            .e-challan-icon{
                margin-right: 10px;
                padding-top: 10px;
            }
            .e-challan-item{
                font-size: small;
            }
        }
    }
    .form-container{
        padding: 14px;
    }
}
.sub-label{
    color: #E2E2E2 !important;
    font-size: 16px;
    margin-bottom: 5px;
}

.css-r47a1p-MuiFormControl-root{
    background-color: "pink";
}

.MuiOutlinedInput-root{
    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
            -webkit-text-fill-color: white !important;
    }
}
