@import '../../Assets/CSS/variables.scss';

.stories-main {
  margin-top: 10px;
}

.stories-text {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}

.story-section {
  width: 110%;
}

.story-img {
  height: 76px;
}

.story-img:hover {
  transform: rotate(-5deg);
}

.story-button {
  background: inherit;
  outline: none;
  border: none;
}
.story-button:focus,
.story-button:active,
.story-button:focus:active {
  background-image: none;
  background: inherit;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.story-ul {
  margin-bottom: 2px;
}
li {
  display: list-item;
}

.sto {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 1rem;
}

.sto::-webkit-scrollbar {
  display: none;
}

.outer {
  background: #212120;
  border: 2px solid $color-primary-red;
  border-radius: 100%;
  padding: 10px;
  height: 77px;
  position: relative;
}

/*  */
.social {
  display: inline-block;
  height: 70px;
  width: 73px;
  border-radius: 100%;
  border: 2px solid $color-primary-red;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.social-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.7rem;
}
/*  */
@keyframes slide-back {
  from {
    /* transform: translateX(100%); */
    opacity: 1;
  }
  to {
    /* transform: translateX(0%); */
    opacity: 0;
  }
}
@keyframes slide-front {
  from {
    /* transform: translateX(0%); */
    opacity: 0;
  }
  to {
    /* transform: translateX(100%); */
    opacity: 1;
  }
}
.display {
  background-color: $color-pure-white;
  position: fixed;
  width: 100%;
  height: 150%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  transition: 10s linear all;
  animation: slide-back 0.5s;
  overflow: hidden;
}

.hidden {
  display: none;
  transition: 10s linear all;
  animation: slide-front 0.5s;
  /* overflow: hidden; */
}
.full {
  width: 100%;
  height: 100%;
}

/* For Skeleton Loading */
.skeleton-sto {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.video_close {
  position: fixed;
  top: 37rem;
  right: 10rem;
  font-weight: 700;
  font-size: 2rem;
  background: inherit;
  outline: none;
  border: none;
}

.video_close:focus,
.video_close:active,
.video_close:focus:active {
  background-image: none;
  background: inherit;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.close-story {
  height: 2rem;
  float: left;
  cursor: pointer;
}

.cancel-icon {
  position: absolute;
  left: 7rem;
  bottom: 34rem;
}

.inside-story-img {
  /* height: 60%; */
  width: 80%;
  flex: 2;
  padding: 20px;
}

.inside-story-para {
  /* transform: translate(0%, 325%); */
  flex: 1;
  color: $color-pure-white;
}

.storyParentBlock {
  /* padding: fixed */
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navButtons {
  position: absolute;
  background-color: transparent;
  width: 50%;
  top: 0px;
  height: 100%;
}

.bgLoader {
  position: absolute;
  background-color: transparent;
  width: inherit;
  height: inherit;
}

.loaderBlock {
  position: relative;
  background-color: blue;
  height: 100px;
}

.rvAni3 {
  width: 0;
  height: 5px;
  background: $color-primary-red;
  position: relative;
  animation: mymovervAni3 10s;
  /* animation-play-state: paused; */
}
.rvAni2 {
  width: 100%;
  height: 5px;
  /* background: green; */
  position: relative;
  /* animation: mymovervAni3 5s; */
  /* animation-play-state: paused; */
}

@keyframes mymovervAni3 {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
