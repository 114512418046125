.product-details-wrapper {
  width: 95%;
  margin: 18px auto 10px auto;
}

.product-describe {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}

.kazam-img {
  width: 100%;
}

.product-name {
  color: #c8c8c8;
  font-weight: 400;
}

.product-rate {
  color: #ffffff;
  margin: 5px 0px 0px 0px;
}

.description {
  margin: 18px 0px;
}

.desc-heading {
  color: #E2E2E2;
  margin-bottom: 8px;
}

.list {
  color: #8d8d8d;
  margin-left: 3px;
}

.product-ul-list {
  margin: 0rem;
}

.product-list {
  list-style: disc outside none;
  margin-left: 1em;
}

.product-list::marker {
  color: #ffffff;
}

.know-more-link {
  margin-bottom: 10px;
}

.know-more-btn {
  outline: none;
  border: none;
  background: none;
  color: #14AE5C;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
}

.interested-btn {
  display: flex;
  padding: 13px 18px;
  background-color: #e02020;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
}

.hr-rule {
  margin: 18px 0px;
}

.product-note {
  width: 95%;
  margin: 18px auto 10px auto;
}

.note-para {
  color: #c8c8c8;
  font-weight: 500;
  font-size: 16px;
}

.note-demo-para {
  color: #8d8d8d;
  font-weight: 300;
  font-size: 14px;
}
