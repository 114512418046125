.state-card {
  border: 1px solid;
  width: 100%;
  padding: 0.4rem;
  border-radius: 5px;
  text-align: center;
  background-color: #0c0c0c;
  color: #c8c8c8;
  cursor: pointer;
}
.state-card-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.state-card-text {
  font-size: 0.9rem;
}

.back-arrow-state {
  width: 40px;
}
