.calculator-info {
  width: 95%;
  margin: 10px auto;
  background-color: #1b2028;
  padding: 10px;
}
.calculator-details {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  padding: 10px;
}
.calculator-details-pink {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  padding: 10px;
  background-color: #c21e66;
  border-radius: 5px;
}

.calculator-details div {
  flex-direction: row;
}

.calculator-details div span {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #8d8d8d;
}

.cal-text {
  flex: 2;
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
}

.cal-number {
  flex: 1;
  color: #c8c8c8;
  text-align: end;
}

.cal-input {
  border: none;
  background: transparent;
  color: #fff;
  outline: none;
  text-align: end;
  width: 50%;
}
.cal-profit-input {
  border: none;
  background: transparent;
  color: #fff;
  outline: none;
  text-align: unset;
  font-size: 18px;
  font-weight: 700;
}

.rv_custom {
  width: 100%;
  font-size: 16px;
  padding: 5px;
  border-bottom: 1px solid #707070 !important;
  text-decoration: none;
}

.rv_custom_pink {
  width: 100%;
  font-size: 16px;
  padding: 5px;
  text-decoration: none;
}

.dark-grey {
  color: #8d8d8d;
}

.hr-width {
  width: 5rem;
  background-color: #707070;
}

.calculator-details-back {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #c21e66;
  border-radius: 7px;
}

select {
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #c8c8c8;
}

select option {
  margin: 40px;
  background: #141414;
  color: #8d8d8d;
  padding: 10px;
}
.border-bottom {
  border-bottom: 1px solid #707070 !important;
}
.dark-grey {
  color: #8d8d8d;
}

.light-grey {
  color: #c8c8c8;
}
