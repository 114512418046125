.TotalEV-Main {
  display: flex;
  gap: 1rem;
  padding-left: 2rem;
  padding-top: 1rem;
}
.all {
  font-size: 20px;
  font-weight: 600;
}
.TotalEV-Card {
  margin: 8px 1rem;
  padding: 16px;
  border-radius: 13px;
  background-image: url('../../Assets/Images/card.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.TotalEv-Wrap {
  display: flex;
  align-items: center;
  grid-gap: 0.6rem;
  gap: 0.6rem;
}
.sales {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 4px;
}
.Total-text {
  font-size: 20px;
  font-weight: 500;
}
.wheeler {
  font-size: 14px;
}
.te-source {
  font-size: 8px;
  color: #8d8d8d;
  margin-top: 6px;
}
