.side_menu{
   margin-top: 5px;
}
.sidebar-not-active{
 border-left: 6px solid transparent;
}

html {
        scroll-behavior: smooth;
      }
.menu_item{
    padding-top: 15px;
}
.border{
    border-top: 1px solid #646464;
    margin: 10px 25px 10px 40px;
    margin-top: 10px !important;
}
.button_border{
    border-top: 1px solid #646464;
    margin: 10px 35px;
    margin-top: 10px !important;
}
.sideBorder{
    border-left: 6px solid #14AE5C;
}
// .home_item{
//     padding-bottom: 5px;
// }
.menu_name{
    padding: 0 41px;
    font-size: 13px;
    padding: 10px 0px;
    color: #E8E8E8;
}
.menu_title{
    display: flex;
    align-items: center;
    .menu_icon{
        margin-right: 10px;
    }
}
.buttons{
    color: black;
    background-color: #F3BE0E;
    font-size: 10px;
    margin: 0px 10px;
    font-weight: bold;
    padding: 6px 6px;
    border: #F3BE0E;
    border-radius: 3px;
}
.profile_div{
    margin: 0px 10px;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 325px !important;
    background-color: black !important;
    color: white !important;
    // opacity: 0.97 !important;
    margin: 0px -16px !important;
}
.css-wf16b5 {
    width: 325px !important;
    background-color: black !important;
    color: white !important;
    // opacity: 0.97 !important;
    margin: 0px -16px !important;
}
.sub_menu{
    padding: 0px 41px;
}
.profile_menu{
    padding: 0px 41px;
}
.back_button{
    padding: 20px 30px 0px 0px;
    text-align: end;
}
.profile_icon{
    text-align: end;
    padding: 20px 30px 0px 0px;
}
.sidemenu-footer{
    bottom: 3rem;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    grid-gap: 1rem;
    padding: 1rem;
    margin-left: 3rem;
}
.droom-text{
    color: #B0B0B0;
    font-size: 12px;
}