@import '../../Assets/CSS/variables.scss';

.tco-detail-main {
  background-image: linear-gradient(0deg, #062121, #044343);
  border-radius: 0.5rem;
  padding: 0.8rem;
}
.tco-detail-owner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  padding: 0.6rem;
}
.tco-detail-list-main {
  padding: 0.6rem;
}
.tco-detail-list {
  display: flex;
  justify-content: space-between;
}
.tco-terms {
  color: $color-grey-dark;
  margin-top: 0.3rem;
  font-size: 14px;
}
.vehicle-list-img {
  width: 100%;
  border-radius: 5px;
}
