.text-blogs-main {
  display: flex;
  flex-direction: column;
  background-color: #1b2028;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  margin-top: 8px;
  /* margin: 4px; */
}
.text-blogs-heading {
  font-size: 15px;
  font-weight: 500;
}
.text-blogs-desc {
  font-size: 13px;
  font-weight: 400;
}
.close-icon {
  padding: 4px;
  background-color: dodgerblue;
  border-radius: 5px;
  width: 20px;
  float: right;
}

/* Material UI Override */
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  width: 100% !important;
}

.blog-img {
  width: 100% !important;
}
.thumbnail-img {
  width: 100%;
}
