@import '../../Assets/CSS/variables.scss';

.location-cards {
  width: 95%;
  height: auto;
  box-shadow: 0px 10px 5px -7px $color-pure-black;
  margin: 10px auto;
  padding: 10px;
  background-color: $color-primary-card;
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.location-name {
  color: $color-light-white;
}

.location-address {
  color: $color-grey-medium;
  font-size: 12px;
  font-weight: 400;
}
.location-sub-details {
  color: $color-grey-medium;
  font-size: 12px;
  font-weight: 400;
  flex: 1rem;
}
.location-info {
  display: flex;
  gap: 5rem;
}
.location-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.6;
}

.location-sub-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.6;
}

.vertical {
  border-left: 2px solid $color-grey-medium;
  height: 15px;
}

.star-img {
  gap: 2px;
}
.ratings {
  display: flex;
  align-items: flex-start;
  gap: 0.2rem;
}

.locationCardLeft {
  flex: 80%;
  /* background-color: green; */
}
.locationCardRight {
  flex: 20%;
  /* background-color: red; */
  display: flex;
  /* padding: 5px; */
  justify-content: flex-end;
  align-items: flex-end;
}
