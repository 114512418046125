.autoComplete {
  display: flex;
  /* padding: 1rem; */
  border: 1px solid;
  max-width: 234px;
  &-input {
    background-color: #141414;
    outline: none;
    padding: 0.4rem 1rem;
    width: 250px;
    height: 40px;
    border: none;
    color: #fafafa;
    font-size: 14px;
  }
  &-searchIcon {
    padding-right: 10px;
  }
}
