@import '../../Assets/CSS/variables.scss';

.location-box {
  margin: 0.6rem;
  background-color: $color-primary-black;
}

.search-box {
  background-color: $color-primary-black;
  bottom: 40px;
  width: 100%;
  // position: absolute;
  padding: 14px;
}

.location-form,
.search-form {
  width: 100%;
  margin-top: 1rem;
}

.form-container,
.search-container {
  display: flex;
  flex-flow: column wrap;
  padding: 5px 14px;
  background-color: $color-primary-card;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  padding-bottom: 12px;
}

.current-location-label,
.destination-location-label,
.last-search-text {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #E2E2E2;
  font-size: 18px;
}
.last-search-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.current-location,
.destination-location {
  width: 100%;
  padding: 14px;
  margin-bottom: 22px;
  border-radius: 4px;
  border: none;
  outline: none;
  border: $color-grey-dark;
  background-color: $color-black-medium;
  color: $color-grey-dark;
}

.edit {
  padding-right: 22px;
}

.find-station {
  padding: 0.8rem 1rem;
  background-color: #14AE5C;
  outline: none;
  border: none;
  color: $color-pure-white;
  border-radius: 4px;
  margin: 10px auto;
  cursor: pointer;
  width: 124px;
  font-size: 16px;
}

.planning-text-main {
  display: flex;
  padding: 10px;
  padding: 4px 0px 4px 0px;
  border-radius: 7px;
  color: #B0B0B0;
}

.planning-input {
  padding: 10px;
  width: 100%;
  margin: 0 10px;
  background: transparent;
  outline: none;
  color: $color-pure-white;
  border: none;
  color: $color-grey-dark;
  font-size: 13px;
  font-weight: 300;
}
.planning-input::placeholder {
  font-size: 0.9rem;
}

.planning-search-image {
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  margin-right: 10px;
}
.planning-source {
  display: flex;
  padding: 4px 0px 4px 10px;
  background: #252525 !important;
  justify-content: space-between;
  border-radius: 7px;
  margin: 5px 0px;
}
.map-Clocation{
  width: 0.8rem;
}
.loc-img {
  width: 1rem;
}
.outline-img{
  width: 1.4rem;
}
.line_icon{
  padding-left: 7px;
}
// .alert-bg{
//   background-color: #1C1C24;
//   color: #FFFFFF;
// }
.swal-overlay--show-modal .swal-modal {
  width: 90%;
}
.swal-modal {
  background-color: #1C1C24;
}
.swal-title {
  color: #FFFFFF;
}
.swal-text {
  color: #B0B0B0;
  text-align: center;
}
.swal-button {
  background-color: #8D8D8D;
  border: none;
}
.swal-footer {
  text-align: center;
}
.swal-button--cancel:focus {
  box-shadow: none;
}